<template>
  <div>
    <div class="card card-custom" v-if="user.is_lab">
      <div class="card-header py-3">
        <div class="card-title w-100 flex-row">
          <div class="w-50">
            <h3 class="card-label font-weight-bolder text-dark w-100">
              <div>Certificados</div>
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >Certificados emitidos para seu laboratório</span
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <CertificateListLab />
      </div>
    </div>
    <div v-else-if="user.admin">Eu sou admin</div>
    <div v-else>
      <CertificatesList />
    </div>
  </div>
</template>

<script>
import CertificateListLab from '../../../components/Certificates/CertificatesListLab'
import CertificatesList from '../../../components/Certificates/CertificatesList'
import { mapGetters } from 'vuex'

export default {
  name: 'CertificatePage',
  components: { CertificateListLab, CertificatesList },
  computed: {
    ...mapGetters(['store']),

    user() {
      return this.$store.state.auth.user
    }
  }
}
</script>
